<template>
  <v-container fluid class="main-container">
    <v-row justify="center">
      <v-col cols="12" lg="4">
        <v-card class="card-view">
          <v-card-title class="card-title">ReminderItem</v-card-title>
          <v-card-text>Reminder : {{ infos.item_count }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="$router.push('reminder')">Go</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" lg="4">
        <v-card class="card-view">
          <v-card-title class="card-title">Notes</v-card-title>
          <v-card-text> Notes : {{ infos.note_count }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn to="notes">Go</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" lg="4">
        <v-card class="card-view">
          <v-card-title class="card-title">Listes</v-card-title>
          <v-card-text>
            Listes : {{ infos.liste_count }} (
            {{ infos.shared_liste_count }}
            partagées)
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn to="listes">Go</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped lang="scss">
.main-container {
}

.card-view {
  margin: 5px;
}

.card-title {
  display: block;
  text-align: center;
}
</style>

<script>
import axios from "axios";

export default {
  name: "HomeView",
  data() {
    return {
      infos: {},
    };
  },
  mounted() {
    axios.get("/infos/").then((response) => {
      this.infos = response.data;
    });
  },
};
</script>
