<template>
  <v-app-bar app class="appbar">
    <v-img
      alt="ArielWeb3 Mermaid Logo"
      class="appbar-img"
      src="/static/mermaid-sm.png"
      transition="scale-transition"
      width="40" />
    <span class="appbar-title">ArielWeb<strong>3</strong></span>
    <v-spacer></v-spacer>
    <div v-if="isAuth">Hello {{ UserName }}</div>
    <v-spacer></v-spacer>
    <v-toolbar-items v-if="!showMenu && isAuth">
      <v-btn to="/" class="appbar-btn">Home</v-btn>
      <v-btn to="reminder" class="appbar-btn">Reminder</v-btn>
      <v-btn to="notes" class="appbar-btn">Notes</v-btn>
      <v-btn to="listes" class="appbar-btn">Listes</v-btn>
      <v-btn v-if="$store.getters.showCalendar" to="calendar" class="appbar-btn"
        >Calendrier
      </v-btn>
      <v-btn v-if="$store.getters.showTasks" to="tasks" class="appbar-btn"
        >Tâches
      </v-btn>
      <v-btn to="clef" class="appbar-btn">C.L.E.F</v-btn>
      <v-btn to="config" class="appbar-btn">Paramètres</v-btn>
      <v-btn to="about" class="appbar-btn">A propos...</v-btn>
    </v-toolbar-items>
    <v-spacer></v-spacer>
    <v-menu v-if="showMenu">
      <template v-slot:activator="{ on }">
        <v-btn icon v-on="on">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item to="/" class="appbar-btn">Home</v-list-item>
        <v-list-item to="reminder" class="appbar-btn">Reminder</v-list-item>
        <v-list-item to="notes" class="appbar-btn">Notes</v-list-item>
        <v-list-item to="listes" class="appbar-btn">Listes</v-list-item>
        <v-list-item to="config" class="appbar-btn">Paramètres</v-list-item>
        <v-list-item to="about" class="appbar-btn">A propos...</v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>
<style scoped lang="scss">
.appbar {
}

.appbar-img {
  flex-shrink: 1;
  flex-grow: 0;
}

.appbar-title {
  font-size: 25px;
}

.appbar-btn {
}
</style>
<script>
export default {
  name: "AppBar",
  methods: {},
  computed: {
    UserName() {
      return this.$store.state.login;
    },
    isAuth() {
      return this.$store.getters.is_auth;
    },
    showMenu() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
};
</script>
