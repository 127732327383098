import Vue from "vue";
import VueRouter from "vue-router";

import HomeView from "../views/HomeView.vue";
import LoginView from "../views/Login";
// import ReminderList from "../views/ReminderList";
// import NoteList from "@/views/NoteGroupList";
// import ListeList from "@/views/ListesList";
// import ListeShoppingMode from "@/views/ListeShoppingMode";
// import CalendarView from "@/views/CalendarView";
// import ConfigEditor from "@/views/ConfigEditor";
import AboutView from "@/views/AboutView";

import store from "../store/index";

Vue.use(VueRouter);

const routes = [
  { path: "/", component: HomeView, name: "home" },
  { path: "/login", component: LoginView, name: "login" },
  // { path: "/validate-password", component: ValidatePwd, name: "validatepwd" },
  {
    path: "/reminder",
    name: "reminder",
    component: () =>
      import(/* webpackChunkName: "reminder" */ "../views/ReminderList.vue"),
  },
  {
    path: "/notes",
    component: () =>
      import(/* webpackChunkName: "notes" */ "../views/NoteGroupList.vue"),
    name: "notes",
  },
  {
    path: "/listes",
    component: () =>
      import(/* webpackChunkName: "listes" */ "../views/ListesList.vue"),
    name: "listes",
  },
  {
    path: "/listeshop/:liste",
    component: () =>
      import(
        /* webpackChunkName: "listesshopping" */ "../views/ListeShoppingMode.vue"
      ),
    name: "listeshop",
    props: true,
  },
  {
    path: "/calendar",
    component: () =>
      import(/* webpackChunkName: "calendar" */ "../views/CalendarView.vue"),
    name: "calendar",
  },
  {
    path: "/tasks",
    component: () =>
      import(/* webpackChunkName: "tasks"*/ "../views/TaskView.vue"),
    name: "tasks",
  },
  {
    path: "/clef",
    component: () => import(/* webpackChunkName:"clef"*/ "@/views/ClefView"),
  },
  {
    path: "/config",
    component: () =>
      import(
        /* webpackChunkName: "configeditor" */ "../views/ConfigEditor.vue"
      ),
    name: "config",
  },
  { path: "/about", component: AboutView, name: "about" },
];

const router = new VueRouter({
  // mode: "history",
  // base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const NonLoggedRoutes = ["login", "askpwdchange", "validatepwd"];
  if (!NonLoggedRoutes.includes(to.name) && !store.getters.is_auth)
    next({ name: "login" });
  else next();
});

export default router;
