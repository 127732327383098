<template>
  <div class="about">
    <h1>A propos...</h1>
    <h3>TODO</h3>
    <v-list>
      <v-list-item>Calendrier : Ajout et modification d'évenements</v-list-item>
      <v-list-item>Calendrier : Export PDF?</v-list-item>
    </v-list>
    <chi-fou-mi />
  </div>
</template>

<script>
import ChiFouMi from "@/components/eastereggs/ChiFouMi";

export default {
  components: { ChiFouMi },
};
</script>
