<template>
  <v-container fluid class="login-container">
    <v-form @submit.prevent="submit_login">
      <v-text-field v-model="login" label="Login" required></v-text-field>
      <v-text-field
        v-model="password"
        label="Password"
        required
        @click:append="ShowPass = !showPass"
        :type="showPass ? 'text' : 'password'"></v-text-field>
      <v-container class="message-box" v-if="$store.getters.has_login_messages">
        <ul>
          <li class="message" v-for="message in messages" :key="message">
            {{ message }}
          </li>
        </ul>
      </v-container>
      <div class="text-center">
        <v-btn class="signin-btn" type="submit" rounded color="white">
          Log In
        </v-btn>
      </div>
    </v-form>
  </v-container>
</template>

<style scoped lang="scss">
.login-container {
  @extend .border;
  @extend .shadow;
  max-width: 600px;
  width: 100%;
}

.message-box {
  @extend .border;
  margin-bottom: 5px;
}

.message {
}
</style>

<script>
export default {
  name: "LoginView",
  data() {
    return {
      login: "",
      password: "",
      showPass: false,
    };
  },
  methods: {
    submit_login() {
      this.$store.commit("set_login", this.login);
      this.$store.commit("set_password", this.password);
      this.$store.dispatch("do_login");
    },
  },
  computed: {
    messages() {
      return this.$store.getters.get_login_messages;
    },
  },
};
</script>
