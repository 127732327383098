<template>
  <v-app>
    <app-bar></app-bar>
    <v-main class="main-container">
      <router-view />
    </v-main>
  </v-app>
</template>
<style scoped lang="scss">
.main-container {
  margin-top: 10px;
  margin-left: 5px;
  margin-right: 5px;
}
</style>

<script>
import AppBar from "./components/AppBar";

export default {
  name: "App",

  data: () => ({
    //
  }),
  components: {
    AppBar,
  },
  mounted() {
    document.title = "ArielWeb3";
  },
};
</script>
