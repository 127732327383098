import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import VueCookies from "vue-cookies";
import VuetifyFloatingMessage from "vuetify-floating-message";
import DateTimePicker from "vuetify-datetime-picker";
import moment from "moment";

Vue.use(VueCookies);
Vue.use(DateTimePicker);
Vue.use(VuetifyFloatingMessage, { vuetify });

Vue.config.productionTip = false;

if (process.env.NODE_ENV === "development") {
  axios.defaults.baseURL = "http://localhost:8000/api";
} else {
  axios.defaults.baseURL = `https://${process.env.VUE_APP_ARIEL_SERVER}`;
}

/* eslint-disable no-param-reassign */
axios.interceptors.request.use((config) => {
  if (store.getters.is_auth) {
    config.headers.Authorization = `Bearer ${store.state.jwt_token}`;
  }
  //console.log(config);
  return config;
});
/* eslint-enable no-param-reassign */

moment.locale("fr-FR");

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
  beforeCreate() {
    this.$store.commit("initialiseStore");
    this.$store.dispatch("refresh_login").then();
  },
}).$mount("#app");
