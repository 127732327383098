import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

import router from "../router";

import colors from "vuetify/lib/util/colors";

Vue.use(Vuex);

/* eslint-disable no-unused-vars */
const store = new Vuex.Store({
  state: {
    jwt_token: null,
    jwt_refresh_token: null,
    login: null,
    password: null,
    categories: [],
    config: {},
    config_id: {},
    show_estimated_time: true,
    color_values: [],
    families: [],
    user_infos: {},
    login_messages: [],
    type_env: [],
  },
  getters: {
    is_auth(state) {
      return state.jwt_token != null;
    },
    get_cred(state) {
      return { username: state.login, password: state.password };
    },
    categories(state) {
      return state.categories;
    },
    get_categorie: (state) => (id) => {
      return state.categories.find((elt) => elt.id === id);
    },
    config(state) {
      return state.config;
    },
    refresh_token(state) {
      return state.jwt_refresh_token;
    },
    show_estimated(state) {
      return state.show_estimated_time;
    },
    get_family: (state) => (id) => {
      return state.families.find((elt) => elt.id === id);
    },
    get_login_messages(state) {
      return state.login_messages;
    },
    showCalendar() {
      return false;
    },
    showTasks() {
      return false;
    },
    has_login_messages(state) {
      return state.login_messages.length > 0;
    },
    getTypeEnv(state) {
      return state.type_env;
    },
  },
  mutations: {
    initialiseStore(state) {
      state.jwt_refresh_token = Vue.$cookies.get("refresh_token");
      this.dispatch("load_colors_alt");
    },
    set_token(state, token) {
      state.jwt_token = token;
      this.dispatch("loadCategories");
      this.dispatch("loadConfig");
      // this.dispatch("load_families");
      this.dispatch("load_type_env");
    },
    set_refresh_token(state, token) {
      state.jwt_refresh_token = token;
      Vue.$cookies.set("refresh_token", state.jwt_refresh_token, "6d");
    },
    set_login(state, login) {
      state.login = login;
    },
    set_password(state, password) {
      state.password = password;
    },
    set_user_infos(state, userinfos) {
      state.user_infos = userinfos;
    },
    set_categories(state, categories) {
      state.categories = categories;
      state.categories.push({ id: -1, text: "Aucune", name: "Aucune" });
      // state.categories.push({ id: -1000, text: "Toutes" });
    },
    add_categorie(state, categorie) {
      state.categories.push(categorie);
    },
    set_config(state, config) {
      //state.config = config;
      config.forEach((elt) => {
        state.config[elt.config] = elt.value;
        state.config_id[elt.config] = elt.id;
      });
    },
    set_families(state, families) {
      state.families = families;
    },
    add_login_message(state, message) {
      console.log(message);
      state.login_messages.push(message);
    },
    set_type_env(state, typeenv) {
      state.type_env = typeenv;
    },
  },
  actions: {
    loadCategories(context) {
      axios.get("/categories/").then((response) => {
        context.commit("set_categories", response.data);
      });
    },
    loadConfig(context) {
      axios.get("/config/").then((response) => {
        context.commit("set_config", response.data);
      });
    },
    load_colors(context) {
      const colors = require("@/utils/colors.json");
      Object.entries(colors).forEach((val) => {
        context.state.color_values.push({
          name: val[0],
          hex: val[1].toUpperCase(),
        });
      });
    },
    load_colors_alt(context) {
      Object.entries(colors).forEach((val) => {
        const name = val[0];
        Object.entries(val[1]).forEach((color) => {
          context.state.color_values.push({
            name: `${name}-${color[0]}`,
            hex: color[1],
          });
        });
      });
    },
    load_families(context) {
      axios.get("/families/").then((response) => {
        context.commit("set_families", response.data);
      });
    },
    load_type_env(context) {
      axios.get("/clef/typeenv/").then((response) => {
        if (response.status === 200) {
          context.commit("set_type_env", response.data);
        }
      });
    },
    set_config(context, config) {
      const data = {
        id: context.state.config_id[config.config],
        config: config.config,
        value: config.value,
      };
      axios.post(`/config/${data.id}/`, data).then((response) => {
        console.log(response);
      });
    },
    do_login(context) {
      context.state.login_messages = [];
      axios
        .post("/auth/login/", context.getters.get_cred)
        .then((response) => {
          context.commit("set_token", response.data.access);
          context.commit("set_refresh_token", response.data.refresh);
          axios.get("/config/user/").then((response) => {
            context.commit("set_user_infos", response.data);
          });
          router.push("/").then(() => {});
        })
        .catch((error) => {
          if (error.response) {
            Object.entries(error.response.data).forEach((entry) => {
              context.commit(
                "add_login_message",
                `Erreur : ${entry[0]} : ${entry[1][0]}`
              );
            });
          } else if (error.request) {
            context.state.login_messages.push(
              `Problème de connection : ${error.message}`
            );
          } else {
            context.state.login_messages.push(
              `Erreur axios : ${error.message}`
            );
          }
        });
    },
    refresh_login(context) {
      if (context.getters.refresh_token) {
        axios
          .post("/auth/refresh/", { refresh: context.getters.refresh_token })
          .then((response) => {
            context.commit("set_token", response.data.access);
            context.commit("set_refresh_token", response.data.refresh);
            router.push("/").then(() => {});
            axios.get("/config/user/").then((response) => {
              context.commit("set_login", response.data.username);
              context.commit("set_user_infos", response.data);
            });
          })
          .catch((error) => {
            if (error.response.status === 401) {
              context.commit("set_token", null);
              context.commit("set_refresh_token", null);
            }
          });
      }
    },
  },
  modules: {},
});
/* eslint-enable no-unused-vars */

export default store;
