<template>
  <v-dialog v-model="dialog" width="80%">
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" icon>
        <v-icon>mdi-controller</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        CHIFOUMI
        <v-spacer />
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="chifoumi-container">
        <div class="chifoumi-score">
          {{ score_human }} - {{ score_computer }}
        </div>

        <div class="restart-btn">
          <v-btn class="pl-auto" icon @click="restart">
            <v-icon>mdi-restart</v-icon>
          </v-btn>
        </div>

        <div class="chifoumi-btn-container">
          <v-btn
            v-for="choice in choices"
            :key="choice.key"
            @click="play(choice.key)"
            class="chifoumi-btn">
            {{ choice.value }}
          </v-btn>
        </div>
        <div class="chifoumi-text-container">
          <div class="chifoumi-text-value">{{ text_value }}</div>
          <div class="chifoumi-result">{{ result }}</div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<style scoped lang="scss">
.chifoumi-container {
  display: grid;
  grid-template-columns: 40% 40%;
}

.chifoumi-score {
  font-weight: bold;
  font-size: xxx-large;
  text-align: center;
  grid-column: 1;
}

.restart-btn {
  grid-column: 2;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
}

.chifoumi-btn-container {
  display: flex;
  flex-direction: column;
  grid-column: 1;
}

.chifoumi-btn {
  width: 40%;
  margin-left: auto;
  margin-right: auto;
}

.chifoumi-text-container {
  @extend .border;
  grid-column: 2;
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  padding: 4px;
  width: 40%;
}

.chifoumi-text-value {
  @extend .border;
  height: 50%;
  font-weight: bolder;
  text-align: center;
}

.chifoumi-result {
  @extend .border;
  height: 50%;
}
</style>

<script>
export default {
  name: "ChiFouMi",
  data() {
    return {
      dialog: false,
      score_computer: 0,
      score_human: 0,
      play_computer: "",
      play_human: "",
      choices: [
        {
          key: "p",
          value: "Pierre",
        },
        {
          key: "f",
          value: "Feuille",
        },
        {
          key: "c",
          value: "Ciseaux",
        },
      ],
      result: "",
    };
  },
  methods: {
    restart() {
      this.score_computer = 0;
      this.score_human = 0;
      this.play_computer = "";
      this.play_human = "";
      this.result = "";
    },
    play(value) {
      this.play_human = value;
      this.play_computer =
        this.choices[(this.choices.length * Math.random()) | 0].key;
      if (this.play_human === this.play_computer) {
        this.result = "Egalité";
      } else {
        if (this.play_human === "p") {
          if (this.play_computer === "f") {
            this.result = "La feuille entoure la pierre. J'ai gagné";
            this.score_computer += 1;
          } else {
            this.result = "La pierre brise les ciseaux. Tu as gagné";
            this.score_human += 1;
          }
        } else if (this.play_human === "f") {
          if (this.play_computer === "p") {
            this.result = "La feuille entoure la pierre. Tu as gagné";
            this.score_human += 1;
          } else {
            this.result = "Les ciseaux coupent la feuille. J'ai gagné";
            this.score_computer += 1;
          }
        } else {
          if (this.play_computer === "p") {
            this.result = "La pierre brise les ciseaux. J'ai gagné";
            this.score_computer += 1;
          } else {
            this.result = "Les ciseaux coupent la feuille. Tu as gagné";
            this.score_human += 1;
          }
        }
      }
    },
    get_value(k) {
      return this.choices.find((elt) => elt.key === k).value;
    },
  },
  computed: {
    text_value() {
      if (this.play_computer.length > 0) {
        return this.get_value(this.play_computer);
      } else {
        return "";
      }
    },
  },
};
</script>
